import { AxiosResponse, AxiosError } from 'axios';
import {
  UserSession,
  UserToken,
  GoogleReCaptcha,
  ChallengeReCaptchaHelpers,
} from '@rmstransactions/components';
import HttpClient from 'utils/api/httpClient';
import { transactionName } from 'config/constants';
import {
  CheckRegistrationVehicleListResponse,
  VHCValidationResponseDetails,
  VHCRequestPaymentResponse,
  VHCConfirmationResponse,
} from 'api/types/response';
import { UserType } from 'api/types/generics';

type AuthPrefixes = '/auth' | '/anon';
export const getAuthPrefix = (): AuthPrefixes =>
  UserSession.isLoginUser() ? '/auth' : '/anon';

export const getUserType = async (): Promise<
  AxiosResponse<UserType> | AxiosError
> => {
  return HttpClient.get('/anon/userType', {
    params: {
      state: transactionName,
    },
  });
};

export const getVehicleList = async (
  plateNumber: string,
  buyVHC: boolean,
  recaptchaChallengeContainerID: string | null
): Promise<
  AxiosResponse<CheckRegistrationVehicleListResponse> | AxiosError
> => {
  const widgetID =
    ChallengeReCaptchaHelpers.getWidgetID(recaptchaChallengeContainerID) ??
    undefined;
  const recaptchaHeaders = await GoogleReCaptcha.getHeaders({
    type: recaptchaChallengeContainerID ? 'challenge' : 'silent',
    action: recaptchaAPIActions.getVehicleList,
    widgetID,
  });

  const pathPrefix = getAuthPrefix();
  return HttpClient.get(
    `${pathPrefix}/cr/vehicle-list/${plateNumber}/${buyVHC}`,
    {
      headers: {
        ...recaptchaHeaders,
      },
    }
  );
};

export const getVehicleDetails = async (
  plateNumber: string,
  plateType: string
): Promise<AxiosResponse<VHCValidationResponseDetails> | AxiosError> => {
  const recaptchaHeaders = await GoogleReCaptcha.getHeaders({
    type: 'silent',
    action: recaptchaAPIActions.getVehicleDetails,
  });

  const pathPrefix = getAuthPrefix();
  return HttpClient.get(
    `${pathPrefix}/cr/vehicle-details/${plateNumber}/${plateType}`,
    { headers: { ...recaptchaHeaders } }
  );
};

export const postRequestPayment = async (
  options: PostRequestPaymentProps
): Promise<AxiosResponse<VHCRequestPaymentResponse> | AxiosError> => {
  const recaptchaHeaders = await GoogleReCaptcha.getHeaders({
    type: 'silent',
    action: recaptchaAPIActions.postRequestPayment,
  });

  const pathPrefix = getAuthPrefix();
  return HttpClient.post(`${pathPrefix}/cr/request-payment`, options, {
    headers: { ...recaptchaHeaders },
  });
};

interface PostRequestPaymentProps {
  plateNumber: string;
  plateType: string;
  amount: number;
  emailAddress?: string;
  kioskId?: string;
}

export const getConfirmationData = async (
  uuid = ''
): Promise<AxiosResponse<VHCConfirmationResponse> | AxiosError> => {
  const path = 'cr/confirmation';

  const recaptchaHeaders = await GoogleReCaptcha.getHeaders({
    type: 'silent',
    action: recaptchaAPIActions.getConfirmationData,
  });

  const missingUserToken = isMissingUserToken();
  if (!missingUserToken) {
    const pathPrefix = getAuthPrefix();
    if (uuid)
      return HttpClient.get(`${pathPrefix}/${path}/${uuid}`, {
        withCredentials: true,
        headers: { ...recaptchaHeaders },
      });
    else
      return HttpClient.get(`${pathPrefix}/${path}/`, {
        withCredentials: true,
        headers: { ...recaptchaHeaders },
      });
  }
  if (uuid) {
    return HttpClient.get(`/support/${path}/${uuid}`, {
      withCredentials: true,
      headers: { ...recaptchaHeaders },
    });
  } else {
    return HttpClient.get(`/support/${path}`, {
      withCredentials: true,
      headers: { ...recaptchaHeaders },
    });
  }
};

export const postSendEmail = async (
  body: PostSendEmailBody
): Promise<AxiosResponse<string> | AxiosError> => {
  const path = 'cr/send-email';

  const recaptchaHeaders = await GoogleReCaptcha.getHeaders({
    type: 'silent',
    action: recaptchaAPIActions.postSendEmail,
  });

  const missingUserToken = isMissingUserToken();
  if (!missingUserToken) {
    const pathPrefix = getAuthPrefix();
    return HttpClient.post(`${pathPrefix}/${path}`, body, {
      withCredentials: true,
      headers: { ...recaptchaHeaders },
    });
  }

  return HttpClient.post(`/support/${path}`, body, {
    withCredentials: true,
    headers: { ...recaptchaHeaders },
  });
};

const isMissingUserToken = (): boolean => {
  const userToken = UserToken.getValue();
  return userToken === null || userToken === 'null';
};

export interface PostSendEmailBody {
  emailAddress: string;
  uuid?: string;
}

export const recaptchaAPIActions = {
  getVehicleList: 'findVehicle',
  getVehicleDetails: 'vehicleDetails',
  postRequestPayment: 'requestPayment',
  getConfirmationData: 'confirmation',
  postSendEmail: 'sendEmail',
};

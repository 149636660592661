import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SectionPage } from '@snsw-gel/react';
import { pdfActions } from '@rmstransactions/components';
import { useStoreSelector } from 'store';
import { confirmationSliceActions } from 'store/slice/confirmation';
import { postSendEmail } from 'api/api';
import { receiptFileName } from 'config/constants';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import { handleResponseData } from 'utils/api/httpClient';
import { RoutePathParams } from 'utils/route/urlParams';
import sessionReceiptUUID from 'utils/session/sessionReceiptUUID';
import KeepAReceipt from './KeepAReceipt/KeepAReceipt';
import { EmailNotificationError } from './EmailNotificationError/EmailNotificationError';
import EmailNotificationSuccess from './EmailNotificationSuccess/EmailNotificationSuccess';
import FailedDownloadPrintReportError from './FailedDownloadPrintReceiptError/FailedDownloadPrintReceiptError';

const ReceiptActions: React.FC = () => {
  const dispatch = useDispatch();
  const confirmationData = useStoreSelector((state) => state.confirmation.data);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sentEmail, setSentEmail] = useState<string>('');
  const [emailPostStatus, setEmailPostStatus] = useState<emailSendStatus>(null);
  const [printDownloadError, setPrintDownloadError] = useState<
    null | 'download' | 'print'
  >(null);
  const { receiptUUID } = useParams<RoutePathParams>();
  const providedReceiptUUID = sessionReceiptUUID.getValue() ?? receiptUUID;
  const receiptPdf = confirmationData?.receiptPDFBase64 ?? null;

  const handlePrint = () => {
    if (!receiptPdf) {
      setPrintDownloadError('print');
      return;
    }
    try {
      pdfActions.printBase64PDF(receiptPdf);
      setPrintDownloadError(null);
    } catch {
      setPrintDownloadError('print');
    }
  };

  const handleDownload = () => {
    if (!receiptPdf) {
      setPrintDownloadError('download');
      return;
    }
    try {
      pdfActions.downloadBase64PDF(receiptPdf, receiptFileName);
      setPrintDownloadError(null);
    } catch {
      setPrintDownloadError('download');
    }
  };

  const handleEmailPost = async (emailAddress: string) => {
    setEmailPostStatus(null);
    setIsLoading(true);

    const response = await postSendEmail({
      emailAddress,
      ...(providedReceiptUUID && { uuid: providedReceiptUUID }),
    });
    const data = handleResponseData(response);
    if (data === null) {
      setEmailPostStatus('error');
      setIsLoading(false);
      return;
    }
    if (typeof data === 'string') {
      sessionReceiptUUID.setValue(data);
    }
    setSentEmail(emailAddress);
    setEmailPostStatus('success');
    dispatch(confirmationSliceActions.setHasReceivedReport(true));
    setIsLoading(false);
  };

  return (
    <>
      <SectionPage>
        <KeepAReceipt
          emailLabelID={emailReceiptActionID}
          labelEmail='Email your receipt and vehicle history report to:'
          onPrint={handlePrint}
          onDownload={handleDownload}
          onEmailSubmit={handleEmailPost}
        />
        <EmailNotificationError visible={emailPostStatus === 'error'} />
        <EmailNotificationSuccess
          visible={emailPostStatus === 'success'}
          emailAddress={sentEmail}
        />
        {printDownloadError && (
          <FailedDownloadPrintReportError action={printDownloadError} />
        )}
      </SectionPage>
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

export default ReceiptActions;

export const emailReceiptActionID = 'receiptActions';
type emailSendStatus = null | 'success' | 'error';
